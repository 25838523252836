import { lazy, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { ErrorBoundary } from 'react-error-boundary'
import { Provider } from 'react-redux'
import store from './store'
import { Route, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom'
import { ConfigProvider, Button, App } from 'antd'
import * as Sentry from '@sentry/react'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import minMax from 'dayjs/plugin/minMax'
// import utc from 'dayjs/plugin/utc'
// import timezone from 'dayjs/plugin/timezone'
import Logo from './images/logo_long.webp'
import packageInfo from '../package.json'
import { addAlpha } from './utils'
import './i18n'
import './index.scss'
import { AiOutlineReload } from 'react-icons/ai'

dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(minMax)
// dayjs.extend(utc)
// dayjs.extend(timezone)
// dayjs.tz.setDefault('Europe/London')

Sentry.init({ dsn: 'https://8b2f3718c2a146ffb99ab35c4ce944b0@o565066.ingest.sentry.io/5706252', release: 'bookingshake@' + packageInfo.version, attachStacktrace: true })

const Main = lazy(() => import('./components/Main'))
const ForgotPassword = lazy(() => import('./components/LoginView/ForgotPwd'))
const ResetPwd = lazy(() => import('./components/LoginView/ResetPwd'))
const OAuthCallback = lazy(() => import('./components/Utils/OAuthCallback'))

const Loader = () => (
	<div style={{ height: '100vh', width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#FEFAF1' }}>
		<img alt="" className="shake" style={{ height: 200 }} src={Logo} />
	</div>
)

const handleReload = () => {
	window.location.reload(false)
}

const ErrorFallback = () => (
	<div style={{ height: '100vh', width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#FEFAF1' }}>
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 32 }}>
			<div style={{ fontWeight: 'bold', fontSize: 16 }}>Error 500</div>
			<Button onClick={handleReload} type="primary" style={{ display: 'flex', alignItems: 'center' }}>
				<AiOutlineReload />
			</Button>
			<div>
				<img className="shake" alt="" style={{ height: 50 }} src={Logo} />
			</div>
		</div>
	</div>
)

const container = document.getElementById('root')
const root = createRoot(container)

const primaryColorSparkle = '#FFCD00'
const primaryColorDarkGrey = '#1D1D1B'
const primaryColorSand = '#FEFAF1'

const curacaoDark = '#629DB7'
// const curacaoLight = '#DEF0F6'
const sunsetDark = '#DC8F4E'
const sunsetlight = '#FFDEC1'
// const mojitoDark = '#869A38'
// const mojitoLight = '#EAEFC9'
// const roseDark = '#E895A8'
// const roseLight = '#F1D7E1'
const tanDark = '#AE9969'
const tanLight = '#FFEBBE'

root.render(
	<ConfigProvider
		theme={{
			token: {
				colorPrimary: primaryColorSparkle,
				colorText: primaryColorDarkGrey,
				colorTextLightSolid: primaryColorDarkGrey,
				colorLink: curacaoDark,
				fontFamily: 'Work Sans',
				borderRadius: 4,
				colorBgSpotlight: primaryColorSparkle,
				colorError: '#FE654F',
				gold: primaryColorSparkle,
				green: '#20BF55',
				blue: curacaoDark,
				colorWarning: sunsetDark,
				colorWarningBg: sunsetlight,
			},
			components: {
				Layout: {
					siderBg: primaryColorDarkGrey,
				},
				Menu: {
					darkItemColor: primaryColorSand,
					darkItemBg: primaryColorDarkGrey,
					darkItemHoverBg: primaryColorSparkle,
					darkItemHoverColor: primaryColorDarkGrey,
					darkItemSelectedBg: primaryColorSparkle,
					darkItemSelectedColor: primaryColorDarkGrey,
					darkGroupTitleColor: primaryColorDarkGrey,
					horizontalItemSelectedColor: sunsetDark,
					itemPaddingInline: 8,
				},
				Table: {
					// rowHoverBg: sunsetlight,
					headerBg: addAlpha(primaryColorDarkGrey, 0.04),
				},
				Select: {
					// optionSelectedBg: primaryColorSparkle,
					optionActiveBg: addAlpha(primaryColorSparkle, 0.2),
				},
				Tabs: {
					itemSelectedColor: curacaoDark,
					itemHoverColor: curacaoDark,
					itemActiveColor: curacaoDark,
					inkBarColor: curacaoDark,
				},
				Radio: {
					buttonSolidCheckedBg: curacaoDark,
					buttonSolidCheckedColor: 'white',
					buttonSolidCheckedHoverBg: curacaoDark,
				},
				Mentions: {
					zIndexPopup: 10000,
				},
				Segmented: {
					trackBg: tanLight,
					itemHoverBg: addAlpha(tanDark, 0.2),
					itemSelectedColor: primaryColorDarkGrey,
					itemColor: addAlpha(primaryColorDarkGrey, 0.7),
				},
			},
		}}
	>
		<App notification={{ maxCount: 1 }}>
			<ErrorBoundary FallbackComponent={ErrorFallback}>
				<Provider store={store}>
					<Suspense fallback={<Loader />}>
						<Router>
							<Routes>
								<Route exact element={<Main />} path="/" />
								<Route exact element={<ForgotPassword />} path="/forgot-pwd" />
								<Route element={<ResetPwd />} path="/reset-pwd" />
								<Route element={<OAuthCallback />} path="/oauthcallback" />
								<Route path="*" element={<Navigate to="/" replace />} />
							</Routes>
						</Router>
					</Suspense>
				</Provider>
			</ErrorBoundary>
		</App>
	</ConfigProvider>
)
